<template>
  <div class="-contact-page-index">
    <PageTitle
      title="Let's Chat."
      subtitle="If you'd like to learn more about me or work together on something, reach out here and I'll get back to you."
      backgroundClass="has-background-white-bis"
      v-bind:isOverlapped="true"
    />
    <section class="section -is-overlapping">
      <div class="container">
        <div class="box">
          <ContactForm />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageTitle from "@/components/ui/PageTitle.vue";
import ContactForm from "@/components/pages/contact/ContactForm.vue";

export default {
  name: "ContactPageIndex",
  components: {
    PageTitle,
    ContactForm
  }
};
</script>

<style scoped lang="scss"></style>
