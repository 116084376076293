<template>
  <div class="-contact-form">
    <form
      action="https://formspree.io/contact.johnbauer.me@gmail.com"
      method="POST"
    >
      <b-field label="Return Email Address" aria-required required>
        <b-input
          v-model="email"
          placeholder="Enter your email address."
          required
          type="email"
          name="email"
        ></b-input>
      </b-field>
      <b-field label="Subject" aria-required required>
        <b-input
          v-model="subject"
          placeholder="What would you like to chat about?"
          type="text"
          name="subject"
        ></b-input>
      </b-field>
      <b-field label="Message">
        <b-input
          maxlength="200"
          v-model="message"
          type="textarea"
          name="message"
          placeholder="Write your message here."
        ></b-input>
      </b-field>
      <input
        class="button is-fullwidth"
        v-bind:class="buttonClass"
        type="submit"
        value="Send"
      />
      <p class="is-size-7 has-text-centered has-margin-top-10">
        I won't share any of your information. If you'd like to request a CV,
        meeting, or additional case studies, let me know in the form above.
      </p>
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      email: null,
      subject: null,
      message: null
    };
  },
  computed: {
    buttonClass: function() {
      if (this.email && this.subject && this.message) {
        return {
          "is-static": false,
          "is-success": true
        };
      } else {
        return {
          "is-static": true,
          "is-success": false
        };
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
