<template>
  <div class="contact">
    <ContactPageIndex />
  </div>
</template>

<script>
import ContactPageIndex from "@/components/pages/contact/PageIndex.vue";

export default {
  name: "home",
  title: "John Bauer | Contact",
  components: {
    ContactPageIndex
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
